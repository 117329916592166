#shareBtn{
    background-color: #534afc;
}

svg{
    display: inline;
}

#linkInfoBoxList{
    display:grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

#viewStaticsBtn{
    background-color: #6C63FF;
}
#viewStaticsBtn:hover {
    background-color: #4e45fd;;
}

.lightBlue{
    color: rgb(113, 113, 209);
}

/* Public user dashboard */
#publicLinkStatsGoBackBtn {
    background-color: #6C63FF;
    width: 262px;
}
#publicLinkStatsGoBackBtn:hover {
    background-color: #534afc;
}
#aelperLink{
    width:262px;
}


@media only screen and (min-width: 700px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 1024px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 1324px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 1650px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 1970px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 2300px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }  
}
@media only screen and (min-width: 2680px) {
	#linkInfoBoxList{
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }  
}
