/* modal styles */
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}
.backdrop #modalContainer {
	width:80%;
	max-width: 700px;
	max-height: 80%;
	margin: 60px auto;
	box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
    background: white; 
}

.backdrop #publicModalContainer {
	width:90%;
	max-width: 316px;
	max-height: 80%;
	margin: 60px auto;
	box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
    background: white; 
}

@media screen and (max-width: 500px) {
	.backdrop #modalContainer {
		width:90%;
	}
}


