@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap");

.landingPageMainMessage {
	font-family: "Roboto", sans-serif;
}

.landingPageSubMessage {
	font-family: "Open Sans", sans-serif;
}

.roboto {
	font-family: "Roboto", sans-serif;
}

.openSans {
	font-family: "Open Sans", sans-serif;
}

.infoBoxOdd {
	/* height: 370px; */
	/*background-color: rgb(242,245,252);*/
	background-color: #F4f4fa;
	width:100%;
}
.infoBoxEven {
	width:100%;
}

.highlightBox {
	background-color: #eeeefa;
	width:100%;
}

::selection {
	background: #b6b2fa;
	/* color: black; */
	/* WebKit/Blink Browsers */
}


/* width */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	/* background: #b6b2fa; */
	border-radius: 10px;
	opacity: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* background: #7069ee; */
	border-radius: 10px;
	background-color: #808080;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	/* background: #5d55fc; */
	background-color: #545454;
}

@media (min-width: 1024px) {
	.image-size-lg{
		width:20rem;
	}
	.infoBoxOdd, .infoBoxEven {
		height: 400px;
	}
}