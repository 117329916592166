.grey-text {
	color: #808080;
}

/* Nav logo hover effect */
.siteLogo:hover {
	filter: invert(0.22);
	transition: all 0.5s ease-in-out;
}

.sitePurple {
	color: #6c63ff;
}

.navBox {
	background-color: #eeeefa;
}