#loginOptionBox{
    display: grid;
    place-items: center;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    width: 290px;
}

#loginOptionBox h2{
    border-bottom: 1px solid rgb(206, 201, 201);
    
}



