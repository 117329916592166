
.install-btn {
	bottom: 7rem;
	right: 1rem;
	height: 80px;
	width: 80px;
	padding-top: 1.05rem;
}

@media (min-width: 640px) {
	.install-btn {
		bottom: 10rem;
		right: 3rem;
	}
}