.userGuide-container {
	/* height: calc(100vh - 76px); */
	background-color: #f4f4fa;
}

.mx-0{
    margin-left:0rem;
    margin-right: 0rem;
}


.carousel-height {
	height: 100%;
}

.swiper-pagination {
	margin-bottom: 25px;
}
.swiper-pagination-bullet {
	height: 20px !important;
	width: 20px !important;
}
.swiper-pagination-bullet-active {
	background-color: #6c63ff !important;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
	position: fixed;
	/* margin:0 8px  !important;
    top: 240px;
    bottom: 0px  !important; 
    
    right: 10px;
    width: 0%;
    display: flex;
    flex-flow: column; */
}

.userGuide-btn {
	bottom: 1rem;
	right: 1rem;
	height: 80px;
	width: 80px;
	padding-top: 1.05rem;
}
.slideImage-style {
}

@media (min-width: 640px) {
	.userGuide-container {
		height: calc(100vh - 76px);
	}
	.userGuide-btn {
		bottom: 3rem;
		right: 3rem;
	}
	.slideImage-style {
	}
}
@media (min-width: 1024px) {
	.slideImage-size-lg{
		width:16rem;
	}
}
@media (min-width: 1370px) {
	.slideImage-size-lg{
		width:18rem;
	}
}
@media (min-width: 1500px) {
	.slideImage-size-lg{
		width:20rem;
	}
}

@media (min-width: 1200px) {
	.carousel-slide-content-width {
		width: 20rem;
	}
}
