#originalUrlForm{
    width: 280px;
}
#shortenUrl{
    width:170px;
   
}

.pl-1rem{
    padding-left: 1rem;
}

